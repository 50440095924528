import { XccEnvironment } from '@xcc-client/services';

// TODO: create new CNAME in so we can make the stage host the stage api
export const environment: XccEnvironment = {
  envMode: 'stage',
  isProduction: false,
  stripeApiPublicKey: 'pk_test_gc1ivmuFgwlAURcJRcqOMjIs00lXn047ZN',
  xccApiHost: 'https://checkout-api.ecom-stage.driversed.com',
  xgritApiHostUrl: 'https://stg.xgritapi.com/api/1',
  xgritAuthorization: 'Basic MDY5MGI2MDc4ZjBmNTUwOTJlMmFiZDNlMmY3ODk3Zjg6',
  xgritWebAppUrl: 'https://app-stg.driversed.com/user/signin',
  brand: 'dec',
  domain: '.driversed.com',
  clientHost: 'https://driversed.com/',
  termsAndConditionsUrl: 'https://driversed.com/terms-and-conditions/',
  recaptchaSiteKey: '6LfQdngjAAAAAI51zzbfB69TEqX60eaw2Sqi-JF2',
  stripeBetas: 'elements_enable_deferred_intent_beta_1',
  stripeApiVersion: '""',
  addPurchaseQueryParam: true,
  ambassador: {
    uid: '88fe223d-6732-4f8a-80e7-e1049b8f9f8b',
    isRecordConversionEnabled: true,
  },
  sentryDsn: '',
  passwordConfig: {
    passwordRegEx: '^.{6,}$',
    passwordNotMatchMessage: 'Your password must be at least 6 characters long',
    showPasswordStrength: true,
  },
  temporaryDownurl: 'course-temporarily-unavailable/',
};
